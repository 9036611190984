/**
 * @class INSTA
 * @author Sarwar Hossain
 * @since 1.0.0
 * 
 * INSTA (Instalogic App) the main application 
 * 
 */
INSTA = {
    version: '2.0.0',
    
    BASE_URL: BASE_URL,
    
    /**
     * Defining namespace 
     */
    Layout: {},
    App: {},
    Lib: {}
    
};
